#cart-container {
  margin-bottom: 60px;
}

.cart-title {
  color: $black;
  font-size: 40px;
  font-weight: 600;
  margin: 95px 0 30px;
  padding: 0 0 5px;
  text-align: center;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    font-size: 30px;
    margin: 35px 0 25px;
  }
}

.cart-product {
  border-bottom: 1px solid $line-color;
  margin-bottom: 35px;
}

.cart-product__title {
  @include caslon;
  font-size: 25px;
  letter-spacing: 0.01em;
  @include breakpoint(medium down) {
    margin-top: 20px;
  }
}

.cart-product__subtitle {
  @include brandon-bold;
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: block;
  margin: 5px 0 25px;
}

.cart-product__col-1 {
  width: 35%;
}

.cart-product__col-2 {
  width: 65%;
}

.btn-cart-remove {
    cursor: pointer;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 7px;

    img {
        width: 22px;
    }
}

.cart-product__buttons {
  text-align: center;
  padding: 35px 0;

    button {
      @include brandon-bold;
      background-color: $black;
      border: 1px solid $black;
      color: white;
      width: 182px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .1em;
      padding: 9px 0 7px;
      position: relative;
      margin: 0 5px;

        &.-light {
          color: $black;
          background-color: white;
        }

        &:active {
          top: 1px;
        }
    }
}

.cart-product__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 30px;

    span {
      @include brandon-bold;
      display: block;
    }

    small {
      font-weight: bold;
      text-decoration: line-through;
      color: $text-grey;
    }
}

.cart-product__price-label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .05em;
}

.cart-product__price-big {
  font-size: 22px;
  margin-top: -5px;
}

.cart-resume {
  margin-left: 50px;
  @include breakpoint(medium down) {
    margin-left: 0;
  }
}

.cart-resume__top {
  //margin-bottom: 20px;

    p {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.4;

        &.-light {
          opacity: .4;
        }
    }

    &.-right {
      text-align: right;

        p {
          font-size: 11px;
          line-height: 1.8;
        }
    }
}

.coupon-input {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

    > * {
      width: 50%;
    }

    input {
      margin: 0 10px 0 0;
      height: 34px;
      box-shadow: none;
      border-color: $line-color;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.15em;
      font-size: 12px;
      padding: 8px 8px 6px;
    }

    .btn {
      font-size: 11px;
      background-color: $black;
      color: white;
      padding: 10px 0 8px;
    }
}

.coupon-label {
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 0.1em;
  margin-bottom: 4px;
  color: #8d8f96;
}

.country-select {
  @include brandon-bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 12px;
  height: 34px;
  padding-left: 11px;
  border-color: $line-color;
}

.cart-total {
  border-top: 1px solid $line-color;
  padding: 20px 0;
  text-align: center;

    span {
      @include brandon-bold;
      display: block;
    }
}

.cart-total__label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .05em;
}

.cart-total__price {
  font-size: 22px;
  margin-top: -5px;
}

.select-carrier {
  margin: 14px 0 22px;
}

.cart-resume__space {
  margin: 0 0 20px;
}