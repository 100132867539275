#map {
    /*
  height:50vh;
  */
    min-height: 400px;
}

#directions {
    margin-bottom: 9px;
    margin-top: 12px;
}

.form-popup {
    display: none;
    border: 3px solid #f1f1f1;
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.information-boutique {
    overflow: auto;
    padding: 42px 28px;
    background-color: $dark-grey;
}

.medium-paragraph {
    @include brandon-medium;
}

.paragraph-box-cta p {
    padding: 0px;
    line-height: 1.7;
    margin: 6px;
    font-size: 22px;
}

.boutique-menu-main-container {
    position: sticky;
    top: var(--header-height, 0px);
    background-color: $white;
    z-index: 100;

    ul > li {
        list-style-type: disc;
        color: $gold;
        padding-bottom: 0;
        margin: 0 12px;
    }

    .boutique-menu {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        li > a {
            @include caslon;
            font-size: 20px;
            line-height: 32px;

            &:hover, &:active, &.active {
                color: $gold;
            }
        }
    }
}
