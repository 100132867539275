.main-menu {
    ul {
        list-style: none;
    }

    > ul > li {
        display: inline-block;
        margin-right: -7px;

        > a {
            @include brandon-medium;
            font-size: 14px;
            font-style: normal;
            padding: 0 15px 5px;
            display: inline-block;
            position: relative;
            z-index: 11;
            text-transform: uppercase;
            &.highlighted {
                @include brandon-medium;
                background-color: $gold;
                color: $white;
                padding: 4px 8px;
            }
            &:hover::after {
                content: "";
                opacity: 1;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 3px;
                background-color: $violet;
            }
        }

        &:hover {
            /*
      a::after {
        opacity: 1;
      }
      */

            .secondary-menu {
                display: block;
            }
        }
    }
}

.secondary-menu {
    display: none;
    position: absolute;
    background-color: $light-grey;
    left: 0;
    right: 0;
    max-width: 1350px;
    margin: 10px auto;
    border: 1px solid $line-color;
    padding: 42px;
    z-index: 10;

    .column-block {
        margin: 0;

        .content-img {
            width: 192.8px;
            height: 192.9px;
            overflow: hidden;

            div.img {
                height: inherit;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
                transition: all 0.3s ease-out;
            }
        }

        a:hover {
            div.img {
                transform: scale(1.1);
            }
        }

        h4 {
            @include brandon-regular;
            text-align: left;
            margin-top: 16px;
        }
    }
}

.menu-side-img {
    a {
        display: block;
    }

    span {
        @include caslon;
        display: block;
        font-size: 18px;
        letter-spacing: 0.01em;
        margin: 12px 0 5px;
    }
}

.menu-big-img {
    text-align: center;

    a {
        display: block;
        margin-bottom: 30px;
    }

    span {
        @include caslon;
        display: block;
        font-size: 18px;
        letter-spacing: 0.01em;
        margin: -6px 0 12px;
    }
}

.secondary-menu__title,
.secondary-menu__title a {
    @include brandon-bold;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}

//menu mobile

#offCanvasMenu {
    > div {
        padding: 129px 45px 45px;
        height: 100%;
        background-color: $light-grey;
        @include breakpoint(small down) {
            padding: 35px 37px 48px;
        }
    }

    .off-menu-image {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        img {
            width: 24px;
            cursor: pointer;
            transition: all 150ms ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .off-menu-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        animation-name: mobile-menu-fade;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;

        &.js-submenu {
            display: none;
        }

        &.js-desktop-menu {
            display: flex;
            @include breakpoint(small down) {
                display: none;
            }
        }

        &.js-submenu-open {
            display: flex;
        }
    }

    ul {
        padding: 0;
        margin-top: 20px;
    }

    li {
        list-style: none;
        margin-bottom: 0px;

        a {
            /* border-bottom: 1px solid $gold; */
            font-size: 14px;
            display: block;
            /* padding: 4px 0 2px;*/
            margin-top: -1px;
            opacity: 0.6;
            /*margin-bottom: 17px;*/
            transition: opacity 150ms ease;
            text-transform: uppercase;
            &.highlighted {
                @include brandon-medium;
                background-color: $gold;
                color: $white;
                padding: 4px 8px;
            }
            @include breakpoint(small down) {
                padding: 0;
                opacity: 1;
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .off-canvas.is-transition-overlap.is-open {
        box-shadow: 0 0 10px hsla(0, 0%, 1%, 0.2);
    }

    .js-mobile-menu-back {
        display: none;
    }

    .js-open-submenu {
        position: relative;

        &::after {
            content: url(/skins/current-skin/images/icons/arrow-right.svg);
            width: 7px;
            height: auto;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }

    .js-mobile-menu.off-menu-text {
        ul li {
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            margin-top: -1px;
            padding: 12px 0px;
        }
    }

    .js-submenu.off-menu-text.js-submenu-open {
        ul li {
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            margin-top: -1px;
            padding: 12px 0px;
        }
    }

    .js-mobile-menu {
        display: none;
        @include breakpoint(medium down) {
            display: flex;
        }
    }
}

.header-main__flex {
    display: flex;
    justify-content: space-between;
}

.header-main__side {
    align-self: center;
    width: 100px;
    display: flex;
    justify-content: center;

    &.-right {
        text-align: right;

        img {
            width: 20px;
            margin: 0 5px;
        }
    }
}

.header-main__center {
    text-align: center;
}

.header-main__logo {
    width: 102px;
    padding: 5px 0px;

    @include breakpoint(small down) {
        padding: 10px 0px 10px;
    }
}

.header-utilities__language-dropdown {
    position: relative;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &::after {
            content: "";
            background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
            background-repeat: no-repeat;
            width: 12px;
            height: 6px;
            margin-left: 8px;
        }
    }

    ul {
        display: none;
        position: absolute;
        list-style: none;
        background-color: $light-grey;
        border: 1px solid $dark-grey;
        width: 120px;
        text-align: center;
        top: 23px;
        left: -25px;
        padding: 5px 0 5px;
    }

    &:hover {
        ul {
            display: block;
        }
    }
}

.off-canvas.is-transition-overlap.is-open {
    box-shadow: 0 0 10px hsla(0, 0%, 4%, 0.2);
}

.menu-icon-left {
    width: 21px;
    margin-right: 5px;
}
