.no-products-found {
  text-align: center;
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      margin-bottom: 25px;
    }
}

#catalog-container {
  position: relative;

  .catalog-spinner {
    display: none;
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;

    &:after{
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation: indeterminate-bar 2s linear infinite;
      animation: indeterminate-bar 2s linear infinite;
      width:100%;
      height:2px;
      background-color: $violet;
      content: '';
      display: block;
    }
  }

  &.catalog-loading {

    #catalog-wrapper {
      opacity: .4;
    }

    .catalog-spinner {
      display: block;
    }
  }
}

.catalog-title {
  text-align: center;
  border-bottom: 1px solid $line-color;
  padding: 15px 0 20px;

    h1 {
      @include caslon;
      font-size: 44px;
      @include breakpoint(small down) {
        font-size: 38px;
      }
    }
}

.catalog-filter-wrapper {
  padding: 30px 0;
}

.catalog-sort {
  display: flex;
  align-items: center;

    span {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 3px 0 0 10px;
    }
}

.catalog-sort__list {
  list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;
    }

    .catalog-sort__svg {
      @include transition-basic;
      width: 20px;
    }

    a {
      @include transition-basic;
      display: flex;
      align-items: center;
      border: 1px solid $black;
      padding: 7px;

        &:hover,
        &.active {
          background-color: $black;

          .catalog-sort__svg path {
            fill: white;
          }
        }
    }
}

.catalog-filter__btn {
  background-color: $violet;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  width: 200px;
  padding: 10px 14px 8px;
  text-align: left;
  position: relative;
  @include breakpoint(small down) {
    width: 100%;
    margin-top: 20px;
  }

    &:focus {
      outline: none;
    }

    img {
      position: absolute;
      width: 13px;
      right: 11px;
      top: 11px;

        &.hide {
          display: none;
        }
    }
}

.layout-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;

    span {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 3px 10px 0 0;
    }
}

.layout-options__item {
  display: inline-block;
  margin-left: 14px;

    svg {
      width: 20px;
    }

    path {
      fill: $black;
    }

    input {
      display: none;

      &:checked + label,
      + label:hover {
        background-color: $black;

          path {
            fill: white;
          }
      }
    }

    label {
      @include transition-basic;
      margin: 0;
      display: flex;
      align-items: center;
      border: 1px solid $black;
      padding: 7px;
    }
}


.filters-wrapper {
  border: 1px solid $line-color;
  padding: 30px;
  display: none;
  @include breakpoint(small down) {
    padding: 5px 30px 20px;
  }
}

.filters-title {
  @include brandon-bold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 20px;
  @include breakpoint(small down) {
    margin: 25px 0 10px;
  }
}

.catalog-filter__img {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 5px;
  cursor: pointer;

    img {
      width: 10px;

        &.hide {
          display: none;
        }
    }
}

.subfilters-list {
  margin-left: 25px;
  display: none;
}

.filters-checkbox {
  max-height: 255px;
  overflow: hidden;

   &.open {
     max-height: none;
   }
}

.filters-checkbox-btn {
  border-top: 1px solid $line-color;
  max-width: 350px;
  margin: 25px auto 0;
  cursor: pointer;
  text-align: center;

    img {
      width: 10px;

      &.hide {
        display: none;
      }
    }
}

.current-filter {
  @include brandon-bold;
  background-color: $black;
  color: white;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  position: relative;
  padding: 5px 10px 3px;
  margin: 0 0 7px;

    img {
      width: 10px;
      position: absolute;
      right: 9px;
      top: 6px;
    }
}

.remove-current-filter {
  @include brandon-bold;
  display: block;
  border: 1px solid black;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  padding: 5px 0 3px;
}

.filter-separator {
  border-top: 1px solid $line-color;
  margin-top: 28px;
  padding-top: 25px;
  @include breakpoint(small down) {
    padding-top: 5px;
  }
}

.catalog-line {
  border: none;
  border-bottom: 1px solid $line-color;
  margin-bottom: 60px;
}
/*
.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;

    &.catalog {
      border-top: 1px solid $line-color;
      margin: 30px 0 80px;
    }

    li {
      display: inline-block;
      margin: 0 8px;
    }

    a {
      @include brandon-bold;
      @include transition-basic;
      border: 1px solid $black;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

        &.active,
        &:hover {
          background-color: $black;
          color: white;
        }
    }

    .pagination__arrow {
      opacity: .5;

        &:hover {
          background-color: white;
          opacity: 1;
        }
    }

    img {
      width: 8px;
    }
}

*/
.promo-tag {
  @include brandon-bold;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #79cffa;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1em;
  padding: 5px 20px 4px;
  z-index: 10;
}

.filters-trigger__minus {
  display: none;
}

.open-filter {

    .filters-trigger__plus {
      display: none;
    }

    .filters-trigger__minus {
      display: block;
    }
}


@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}