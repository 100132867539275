.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include caslon;
}

.checkout-step {
  border-bottom: 1px solid $line-color;
  padding: 0 0 7px 40px;
  margin: 15px 0 20px;
  position: relative;
  letter-spacing: 0.01em;

    &::before {
      @include brandon-bold;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 16px;
      position: absolute;
      left: 0;
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 1px 0 0 2px;
      font-size: 14px;
    }

    &.first::before {
      content: "1";
    }
    &.second::before {
      content: "2";
    }
    &.third::before {
      content: "3";
    }

    &.active::before {
      color: white;
    }
}

.checkout-buttons {
  margin: 0 0 40px;

    .btn {
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}