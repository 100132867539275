// features head
/*
.contact-head {
  text-align: center;
  padding: 60px 0 40px;
  position: relative;
  z-index: 1;
  @include breakpoint(small down) {
    padding: 50px 0 20px;
  }

  h1 {
    font-size: 28px;
    @include breakpoint(small down) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    padding-top: 12px;
    @include breakpoint(small down) {
      font-size: 16px;
    }
  }
}
*/

/*
.contact {
  background-color: $black;
  padding: 30px 0 140px;
  position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 200px;
      background: $white;
      transform: skewY(-3deg);
      top: -60px;
      left: 0;
    }
}
*/

.section-popup {
  display: flex;
  justify-content: center;
}

.popup {
  width: 80%;
  position: fixed;

  @media screen and (max-width: 39.9375em) {

    top: 150px;

  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {

    top: 120px;

  }

  @media screen and (min-width: 64em) {

    top: 170px;

  }

  z-index: 100;
}

.contact__form-container {
  /*
  background-color: $white;
  */
  border-radius: 8px;
  position: relative;
  z-index: 1;
  /*
  box-shadow: 0 2px 15px rgba(67, 73, 108, 0.18);
  */

  &.form-landing
  {
    background-color: $light-grey;
    padding: 30px;
    border-radius: 0px;
  }
}

.contact_check-form{
  display:table;
}

.form_designer {

  .column-1 {
    background: #EAEAEA;
    text-align: center;
    padding: 40px;

    div.box {
      margin: 0 auto;
      margin-top: 30px;
      width: 200px;

      ul {
        text-align: left;
      }
    }
  }

  .column-2 {
    padding: 40px;
    background: #F7F7F7;
    @include breakpoint(small down) {
    padding: 75px 40px;

  }
}

  .active {
    background-color: #B79962 !important;
  }

  .dot {
    display: inline-block;
    border: 2px solid #b79962;
    width: 24px;
    height: 24px;
    /*background: white;*/
    border-radius: 30px;
    z-index: 999;
    cursor: pointer;
    content: '';
    color: transparent;
    font-size: 14px;
    line-height: 10px;
    vertical-align: middle;
    margin: 5px 10px 8px 0;
    font-weight: 300;
  }
}

[type='text'], [type='email'], [type='password'], textarea{
  box-shadow: none;
  border: 1px solid $dark-grey;
  border-radius: 3px;
  color: $black;

  &:focus{
    border: 1px solid $gold;
    box-shadow: none;
  }

}

//form desginer

.reveal-overlay {
  z-index: 110;

}