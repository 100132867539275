// colors
$black: #2F2D32;
$black-hover: #191919;
$light-grey: #F7F7F7;
$dark-grey: #EAEAEA;
$violet: #9B93BE;
$gold: #B79962;
$gold-hover: #977D4E;
$white: #ffffff;
$line-color: #e3e6ea;
$red: red;
$text-grey: #EAEAEA;

// Slick slider
$slick-font-path: '/skins/opificio/fonts/slick/';
$slick-loader-path: '/skins/opificio/images/slick/';