// main header
.header-main {
  z-index: 100;
  background-color:#fefefe;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    padding: 10px 0 9px;
  }
}

.header-main.sticky {
  position: fixed;
  width: 100%;
  top: 0;
}

