.cart-summary {
  margin-left: 40px;
  @include breakpoint(medium down) {
    margin-left: 0;
  }

    h2 {
      border-bottom: 1px solid $line-color;
      padding: 15px 0 7px;
      margin: 0 0 35px;
      color: $text-grey;
      @include breakpoint(medium down) {
        margin: 20px 0 10px;
      }
    }
}

.cart-summary__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 4px 0;

    span {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 14px;
      color: $text-grey;
      letter-spacing: 0.05em;
    }

    .label {
      font-size: 11px;
    }
}

.cart-summary__separator {
  border: none;
  border-top: 1px solid $line-color;
  margin: 8px 0 10px;
}