.alert-image {

  @media screen and (max-width: 39.9375em) {
    width: 80px;
    padding: 60px 0 40px 0;
  }

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    width: 100px;
    padding: 60px 0 40px 0;
  }

  /* Large only */
  @media screen and (min-width: 64em){
    width: 120px;
    padding: 60px 0 40px 0;
  }

}

.alert-box {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  .alert-container {
    @media screen and (max-width: 39.9375em) {
      width: 50%;
      margin-top: 10px;
    }
  
    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      width: 35%;
      margin-top: 60px;
    }
  
    /* Large only */
    @media screen and (min-width: 64em){
      width: 25%;
      margin-top: 60px;
    }
    
    height: max-content;
    box-shadow: 2px 2px 15px 1px #EAEAEA;
    text-align: center;
  }

  .alert {
    @include brandon-bold;
    padding: 20px 0;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: white;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .alert-message {
    @include brandon-bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    padding: 60px 0;
    color: black;
    font-weight: 500;
  }

  .alert-danger {
    background-color: RGB(240,81,97);
    text-align: center;
  }

  .alert-success {
    background-color: $violet;
    box-shadow: 2px 2px 15px 1px #EAEAEA;
  }
}

@keyframes alert {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}