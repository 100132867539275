#product-slider-nav .slick-slide {
  padding: 0 5px;
}

.product-container {
  margin: 95px 0 50px 0;
  @include breakpoint(small down) {
    margin: 12px 0;
  }
}

.slider-wrapper {
  margin-right: 50px;
  @include breakpoint(medium down) {
    margin-right: 0;
  }
}

.product-slider {
  border: 1px solid $line-color;
  margin-bottom: 10px;
}

#product-slider-nav .slick-track .slick-current img {
  border: 1px solid #2b2b2b;
}

.nav-slide {
  border: 1px solid $line-color;
}

.list-tag {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include breakpoint(small down) {
    margin-top: 20px;
  }

    li {
      margin: 0 5px;
      @include breakpoint(small down) {
        margin: 0 2px 2px;
      }
    }

    a {
      @include brandon-bold;
      @include transition-basic;
      color: $text-grey;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      border: 1px solid $line-color;
      padding: 5px 15px 3px;

        &:hover {
          color: $black;
          border-color: $black;
        }
    }
}

.product-info__title {
  @include caslon;
  font-size: 42px;
  margin: 14px 0;
  @include breakpoint(small down) {
    font-size: 28px;
  }
}

.product-info__subtitle {
  @include brandon-bold;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.05em;
  @include breakpoint(small down) {
    font-size: 11px;
  }
}

.product-info__descr {
  max-width: 260px;
  text-transform: uppercase;
  font-size: 11px;
  margin: 18px auto;
  letter-spacing: 0.05em;
  @include breakpoint(small down) {
    font-size: 10px;
    margin: 14px auto 18px;
  }
}

.product-info__price {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $line-color;
  border-bottom: 1px solid $line-color;
  padding: 20px 0 15px;
}

.product-info__single-price {
  @include brandon-bold;
  font-size: 22px;
  text-align: center;
  @include breakpoint(small down) {
    font-size: 19px;
  }

    span:first-child {
      @include brandon-bold;
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.05em;
      margin-bottom: -6px;
      @include breakpoint(small down) {
        font-size: 10px;
      }
    }

    span:last-child {
      @include brandon-bold;
    }

    &.-light {
      opacity: .4;
    }
}

.product-info__size-table {
  text-align: center;
  margin: 20px 0 45px;

    a {
      @include brandon-bold;
      @include transition-basic;
      color: $text-grey;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      border: 1px solid $line-color;
      padding: 5px 15px 3px;

      &:hover {
        color: $black;
        border-color: $black;
      }
    }
}

.i-number-btn {
  background-color: $black;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;

    img {
      width: 14px;
    }
}

.qty-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
}

.i-number {
  @include brandon-bold;
  margin: 0;
  height: 35px;
  box-shadow: none;
  width: 70px;
  text-align: center;
  border: none;
  background-color: white;
  cursor: default;
}

.qty-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

    th {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .05em;
      padding-bottom: 5px;
    }

    td {
      @include brandon-bold;
      border-top: 1px solid $line-color;
      border-bottom: 1px solid $line-color;
      padding: 6px 0 4px;
    }

    .availability {
      opacity: .4;
    }
}

.qty-max {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid $black;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 8px;
  position: relative;

    &:active {
      top: 1px;
    }
}

.qty-table-resume {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

    button {
      @include brandon-bold;
      background-color: $black;
      color: white;
      width: 182px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.1em;
      padding: 9px 0 7px;
      position: relative;

        &:active {
          top: 1px;
        }
    }

    span {
      @include brandon-bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: .05em;

        &:first-child {
          font-size: 11px;
          padding-top: 5px;
        }
    }

    td {
      border-bottom: 1px solid $line-color;
      padding: 25px 0;

        &:first-child {
          opacity: .4;
        }
    }
}

.size-col-1 {
  width: 25%;
}

.size-col-2 {
  width: 25%;
}

.size-col-3 {
  width: 50%;
}

.price-total {
  text-align: center;
  padding: 18px 0 12px;

    span {
      @include brandon-bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: .05em;

      &:first-child {
        font-size: 11px;
        margin-bottom: -6px;
      }

      &:last-child {
        font-size: 22px;
      }
    }
}

.related-title {
  text-align: center;
  border-bottom: 1px solid $line-color;
  color: $text-grey;
  font-size: 40px;
  font-weight: 600;
  margin: 60px 0 40px;
  padding: 0 0 10px;
  @include breakpoint(small down) {
    font-size: 30px;
    margin: 50px 0 30px;
    padding: 0 0 6px;
  }
}

.list-colors-wrapper {
  text-align: center;
  border-bottom: 1px solid $line-color;
  padding: 25px 0 28px;

    p {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: -2px;
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}

.list-colors {

    li {
      display: inline-block;
      margin: 0 8px;
    }

    span {
      @include transition-basic;
      width: 58px;
      height: 5px;
      display: inline-block;
      @include breakpoint(small down) {
        width: 40px;
        height: 8px;
      }
    }

    a:hover,
    a.active {
      span {
        transform: scaleY(2.8);
      }
    }
}