.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include brandon-bold;
      font-size: 60px;
      line-height: 1;
      letter-spacing: 0.05em;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
    }

    p {
      margin: 3px 0 25px;
    }
}


.cms-page{
  margin:20px 0px 20px 0px;

  u {
    text-decoration: underline;
  }
  
  h1{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 20px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h2{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 16px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h3,h4{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 14px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  
  
  ul{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  ol{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
}

.cms-cover-title{
  color: $white;
  font-size:40px;
  margin-top:30px;
  @include breakpoint(small down) {
   font-size:30px;
  }

  &.coming-soon {
    font-size: 120px;
    line-height: 124px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    @include breakpoint(medium down) {
      justify-content: flex-end;
      font-size:80px;
      line-height: 88px;
     }
  }
}

.coming-soon-container {
  padding: 72px 0;
  text-shadow: none;
}

.list-tessuti{
  list-style-image: url(/skins/current-skin/images/icons/list.svg);
  margin-left: 22px;
}


 //masonry
 
/*
 .masonry {
  display: flex;
  width: 100%;
  @include breakpoint(small down) {
    margin-left:0px;
  }
}
*/


/*
.masonry-brick {
  overflow: hidden;
  border-radius: 5px;
  margin: 0 0 8px 8px;  /* Some Gutter 
  color: white;
  position: relative;
  @include breakpoint(small down) {
    margin-right: 0px;
  }
}

.masonry-brick:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  counter-increment: brick;
  
  transition: font-size .25s, opacity .25s ease-in-out;
  font-weight: 700;
  opacity: .5;
  font-size: 1.25em;
}

.masonry-brick:hover:after {
  font-size: 2.25em;
  opacity: 1;
}

.masonry-brick--h {
  flex: auto;
  height: 250px;
  min-width: 150px;
}

@media only screen and (min-width: 1024px) {
  /* Horizontal masonry bricks on desktop-sized screen 
  .masonry-brick--h:nth-child(4n+1) {
    width: 350px;
  }
  .masonry-brick--h:nth-child(4n+2) {
    width: 325px;
  }
  .masonry-brick--h:nth-child(4n+3) {
    width: 180px;
  }
  .masonry-brick--h:nth-child(4n+4) {
    width: 380px;
  }

  /* Adjusting vertical masonry height on desktop-sized screen 
  .masonry--v {
    max-height: 1600px;
  }

  /* Vertical masonry bricks on desktop-sized screen 
  .masonry-brick--v {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* Horizontal masonry bricks on tabled-sized screen 
  .masonry-brick--h:nth-child(4n+1) {
    width: 200px;
  }
  .masonry-brick--h:nth-child(4n+2) {
    width: 250px;
  }
  .masonry-brick--h:nth-child(4n+3) {
    width: 120px;
  }
  .masonry-brick--h:nth-child(4n+4) {
    width: 280px;
  }

  /* Adjusting vertical masonry height on tablet-sized screen 
  .masonry--v {
    max-height: 2000px;
  }

  /* Vertical masonry bricks on tablet-sized screen 
  .masonry-brick--v {
    width: 50%;
  }
}
*/
//ebt
.ebt-loghi{
  margin-left:120px;
  @include breakpoint(medium down) {
    margin-left:60px;
  }
}

.mobile-ebt{
@include breakpoint(small down) {
  margin-left:0px;
  margin-bottom:20px;
}
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

//slider ebt

#img-ebt, .slider-cms{

.slick-dots li button:before{
  color: $black;
  font-size: 10px;
  line-height: 30px;
}

.slick-dots li.slick-active button:before {
  color: $gold;
  opacity: 1;
}

.slick-next:before, .slick-prev:before{
  color: $gold;
  font-size:40px;
  opacity:1;
}

.slick-prev{
  left:40px;
  z-index:1000;
  width: 40px;
  height: 40px;
}
.slick-next {
  right: 60px;
  z-index:1000;
  width: 40px;
  height: 40px;
}

.slick-prev:before {
  content: url(/skins/current-skin/images/icons/freccia-sx-slider.svg);
}

.slick-next:before {
  content: url(/skins/current-skin/images/icons/freccia-dx-slider.svg);
}
}

//masonry gallery no text
.masonry { 
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;
  @include breakpoint(small down) {
    margin-left:0px;
  }
  @include breakpoint(medium down) {
    margin-left: 0px;
  }
}

.masonry-brick {
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 0 8px 8px 0; /* Some gutter */
  @include breakpoint(small down) {
    margin-right: 0px;
}
@include breakpoint(medium down) {
  margin-right: 0px;
}
}

.masonry-brick {
  &:nth-child(4n+1){
     width: 250px;
  }
  &:nth-child(4n+2){
     width: 325px;
  }
  &:nth-child(4n+3){
     width: 180px;
  }
  &:nth-child(4n+4){
     width: 380px;
  }
}

.masonry-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/*
.masonry--h {
  flex-flow: row wrap;
}

.masonry--v {
  flex-flow: column wrap;
  max-height: 1080px;
}

.masonry--h,
.masonry--v {
  margin-left: -8px; /* Adjustment for the gutter 
  counter-reset: brick;
}
*/


.pagination a {
  @include brandon-regular;
  transition: all .15s ease-in-out;
  border:none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination a:hover {
  background-color: $gold;
  color: $white;
}

.pagination .active a, .pagination a:hover {
  border-radius: 50%;
  background-color: $gold;
  color: $white;
}


//pagination

.pagination li{
  @include breakpoint(small down) {
    display: inline-block;
  }
}

ul.pagination a {
  @include breakpoint(small down){
    width: 31px;
    height: 31px;
  }
}


.row-filter{
  justify-content:space-between;
  
  @include breakpoint(medium down) {
    margin-top:15px;
  }
  
}


/*
.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:60px 0px;

    li {
      display: inline-block;
      margin: 0 8px;
    }

    a {
      @include brandon-regular;
      @include transition-basic;
      border: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

        &:hover {
          background-color: $gold;
          color: $white;
          border-radius: 50%;
        }
        &.active{
          background-color: $gold;
          color: $white;
          border-radius: 50%;
        }
    }

    img {
      width: 8px;
    }
}

*/
//blog article


.search-store-box {

  margin: 10px;
  padding: 15px;

  @include breakpoint(small down) {
    margin: 0px;
  }

}

.centered-padding-paragraph{
  padding: 0 20%;
  @include breakpoint(small down) {
    padding: 0px;
  }
}

#blog-banner-cms {

  @include breakpoint(medium down) {
    order: 2;
    text-align: center;
  }

  .item {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

  .item-1 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 10px 0px 0px;
    }
  }

  .item-2 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 0px 0px 10px;
    }
  }

  img {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

}

.velluti-blocco {
  align-self: center;
}


#text-block-banner-1, #text-block-banner-2 {

  margin-left: 10px;

  @include breakpoint(small down) {
    margin-left: 0px;
  }
}

#last-post {

  @include breakpoint(small down) {
    margin-bottom: 10px;
  }

}

.related-post-title {
  padding: 25px 0;
  border-top: 1px solid $dark-grey;
  margin-top: 60px;
}

#map {
  height: 100%;
}

.map-container {

  padding: 10px 0 10px 10px;

  @include breakpoint(small down) {
    padding: 0px;
  }

}

.store-overflow {
  overflow: auto;
  height: calc(100vh - 152px);

  @include breakpoint(small down) {
    overflow: unset;
    height: auto;
  }

}

.store-container {
  margin:10px;
  padding:10px;
  display:flex;
  flex-direction:column;
  @include breakpoint(small down) {
    margin: 0;
  }
}

.store-container-torino {
    margin: 10px;
    padding: 10px;
    display: flex;
    border:1px solid $gold;
  }
  
  
.img_store-container-torino{
  @include breakpoint(small down) {
    display:none;
}
}

#blog-banner-1 {
  height: 25vh;
  margin-bottom: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-bottom: 0px;
  }

  @include breakpoint(small down) {
    padding-bottom: 5px;
  }


}

#blog-banner-2 {
  height: 24vh;
  margin-top: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-top: 0px;
  }

  @include breakpoint(small down) {
    padding-top: 5px;
  }
}

.text-center{
  text-align:center;
}

//reserved area

.reserved-area-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

  .sub-title-text-centered p {
    padding: 0px;
    line-height: 1.7;
    margin: 6px;
  }
  
  
  //press
   
  .row.press {

    .summary {
      h2 {
        font-size: 2em;
      }
      .blog-date {
        font-weight: bold;
      }
    }

  }

  .image-cover-blog{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  }

  .title-newsletter {
    @include brandon-regular;
  }

  .newsletter-link {
      font-size: 20px;
      text-transform: uppercase;
      margin-top: 20px;
      color: $white;

      @include breakpoint(medium down) {
        padding-left: 30px;
      }
  }

  //sustainability

  .image-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
  }