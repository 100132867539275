//blog
/*
.smooting-corners-blog-1 {
  border-radius: 0px 50px;
  */
.smooting-corners-blog-2 {
  margin-top: 13px;
  border-radius: 50px 0px;
}

.blog-post-text {
  text-align: left;

  h1 {
    font-size: 40px;
    text-align: center;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }
}

.blog-post-summary-container {
  &:hover {
    .blog-post-image div {
      transform: scale(1.1);
    }
  }
}

.blog-post-summary-container .blog-post-image div {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: inherit;
  transition: all 0.3s ease-out;
}

.blog-post-summary-container .blog-post-image {
  height: 250px;
  overflow: hidden;
}

.blog-post-summary-container .blog-post-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.blog-post-summary-container .blog-post-text {
  margin-top: 15px;
}

.blog-date {
  margin-top: 20px;
}

.blog-date-archive {
  cursor: pointer;

  &:hover {
    color: $gold;
  }
}

.title-article-blog {
  text-align: center;
}

.blog-post-image {
  text-align: center;
}

.related-post-title {
  h2.title-article-blog {
    font-size: 35px;
    text-align: center;
  }
}

.title-banner-left-blog {
  color: $white;
  font-size: 32px;
}

.blog-social__button {
  transition: all 0.15s ease-out;
  border: 1px solid;
  border-radius: 2px;
  padding: 13px 40px;
  position: relative;
  display: inline-block;
}

.blog-container {
  .blog-post-date {
    text-align: center;
    padding: 24px 0;
    @include breakpoint(medium down) {
      padding: 8px 0;
    }
  }

  h1 {
    @include caslon;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  h2 {
    @include brandon-regular;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      font-size: 30px;
      line-height: 45px;
    }
  }

  h3 {
    @include brandon-regular;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      font-size: 24px;
      line-height: 40px;
    }
  }

  h4 {
    @include brandon-regular;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  p,
  span {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    @include breakpoint(medium down) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  strong,
  b {
    @include brandon-medium;
  }

  em {
    font-weight: inherit;
  }

  a {
    &:not(.btn) {
      color: $gold;
      text-decoration: underline;
      font-weight: inherit;
    }
  }

  ul {
    li {
      list-style-type: disc;
      padding: 8px 0;
      margin-left: 24px;
      font-size: 18px;
      line-height: 28px;
      @include breakpoint(medium down) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  ol {

    li {
      list-style-type: decimal;
      padding: 8px 0;
      margin-left: 24px;
      font-size: 18px;
      line-height: 28px;
      @include breakpoint(medium down) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .description-blog {
    text-align: center;
    @include brandon-medium;
  }
}
