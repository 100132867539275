@mixin caslon {
  font-family: 'caslon', Georgia, serif;
}

@mixin brandon-thin {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 200;
}

@mixin brandon-light {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 300;
}

@mixin brandon-regular {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin brandon-medium {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 500;

}

@mixin brandon-bold {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

@mixin brandon-black {
  font-family: 'brandon', Arial, Helvetica, sans-serif;
  font-weight: 700;
}


@mixin transition-basic {
  transition: all .15s ease-in-out;
}